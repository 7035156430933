<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button text="Retour" :default-href="`/report-sheets/${sheet?.uuid}`"></ion-back-button>
                </ion-buttons>
                <ion-title>Rapport : {{ sheet?.title }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div v-if="!loaded">
                <EquipmentSkeleton/>
            </div>
            <div v-else>
                <div :class="$style['summary-data-wrapper']">
                    <ion-grid>
                        <ion-row :class="$style['title']">Personnel ({{ sheet?.employees.length ?? 0 }})</ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['header']">
                            <ion-col>Matricule</ion-col>
                            <ion-col>Personnel</ion-col>
                            <ion-col>Activité</ion-col>
                            <ion-col>Commentaire</ion-col>
                            <ion-col>Déplacement</ion-col>
                            <ion-col>Mobilité</ion-col>
                            <ion-col>Début</ion-col>
                            <ion-col>Fin</ion-col>
                            <ion-col>Moins</ion-col>
                            <ion-col>Total</ion-col>
                            <ion-col>Code mobilité</ion-col>
                            <ion-col>Prest. autres</ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['data']" v-for="employee in sheet?.employees" :key="employee.uuid">
                            <ion-col>{{ employee.reference }}</ion-col>
                            <ion-col>{{ employee.firstname }} {{ employee.lastname }}</ion-col>
                            <ion-col>{{ employee.activity }}</ion-col>
                            <ion-col>{{ employee.comment }}</ion-col>
                            <ion-col>{{ employee.travel }}</ion-col>
                            <ion-col>{{ employee.mobility }}</ion-col>
                            <ion-col>{{ employee.start_at }}</ion-col>
                            <ion-col>{{ employee.end_at }}</ion-col>
                            <ion-col>{{ employee.rest_time }}</ion-col>
                            <ion-col>{{ employee.total_hours }}</ion-col>
                            <ion-col>{{ employee.mobility_code }}</ion-col>
                            <ion-col>{{ employee.services_other }}</ion-col>
                        </ion-row>
                    </ion-grid>
                </div>

                <div :class="$style['summary-data-wrapper']">
                    <ion-grid>
                        <ion-row :class="$style['title']">Matériel ({{ sheet?.equipments.length ?? 0 }})</ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['header']">
                            <ion-col>Référence</ion-col>
                            <ion-col>Matériel</ion-col>
                            <ion-col>Unité</ion-col>
                            <ion-col>Quantité</ion-col>
                            <ion-col>Personnel</ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['data']" v-for="equipment in sheet?.equipments" :key="equipment.uuid">
                            <ion-col>{{ equipment.reference }}</ion-col>
                            <ion-col>{{ equipment.name }}</ion-col>
                            <ion-col>{{ equipment.unity }}</ion-col>
                            <ion-col>{{ equipment.quantity }}</ion-col>
                            <ion-col>{{ equipment.employee_firstname }} {{ equipment.employee_lastname }}</ion-col>
                        </ion-row>
                    </ion-grid>
                </div>

                <div :class="$style['summary-data-wrapper']">
                    <ion-grid>
                        <ion-row :class="$style['title']">Location ({{ sheet?.locations.length ?? 0 }})</ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['header']">
                            <ion-col>Location IN/OUT</ion-col>
                            <ion-col>Unité</ion-col>
                            <ion-col>Quantité</ion-col>
                            <ion-col>Personnel</ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['data']" v-for="location in sheet?.locations" :key="location.uuid">
                            <ion-col>{{ location.name }}</ion-col>
                            <ion-col>{{ location.unity }}</ion-col>
                            <ion-col>{{ location.quantity }}</ion-col>
                            <ion-col>{{ location.employee_firstname }} {{ location.employee_lastname }}</ion-col>
                        </ion-row>
                    </ion-grid>
                </div>

                <div :class="$style['summary-data-wrapper']">
                    <ion-grid>
                        <ion-row :class="$style['title']">Travail sous-traités ({{ sheet?.outsourced_works.length ?? 0 }})</ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['header']">
                            <ion-col>Travail sous-traité</ion-col>
                            <ion-col>Unité</ion-col>
                            <ion-col>Quantité</ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-grid>
                        <ion-row :class="$style['data']" v-for="outsourced_work in sheet?.outsourced_works" :key="outsourced_work.uuid">
                            <ion-col>{{ outsourced_work.name }}</ion-col>
                            <ion-col>{{ outsourced_work.unity }}</ion-col>
                            <ion-col>{{ outsourced_work.quantity }}</ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonPage,
        IonToolbar,
        IonGrid,
        IonRow,
        IonCol
    } from "@ionic/vue"

    import { defineComponent } from "vue"
    import { ReportSheet } from "@/report-sheets"
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue"

    export default defineComponent({
        components: {
            IonBackButton,
            IonButtons,
            IonContent,
            IonHeader,
            IonPage,
            EquipmentSkeleton,
            IonToolbar,
            IonGrid,
            IonRow,
            IonCol
        },
        data() {
            return {
                sheet: null as ReportSheet | null,
                loaded: false as boolean | false
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.axios.get("/report-sheets/" + this.$route.params.uuidReport)
                .then((response) => {
                    this.sheet = response.data
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loaded = true
                })
            }
        }
    })
</script>

<style lang="scss" module>
    ion-grid {
        padding: 0;
    }
    ion-row.title {
        background: #000;
        color: #fff;
        display: flex;
        justify-content: center;
    }
    ion-row.header {
        background: #9e9e9e;
    }
    ion-row.data:nth-child(even) {
        background: #ededed;
    }
    .summary-data-wrapper {
        margin: 60px;
        border: 1px solid #9E9E9E;
    }
</style>