import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_UserSelectorButton = _resolveComponent("UserSelectorButton")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: "Retour",
                    "default-href": `/report-sheets/${_ctx.$route.params.uuidReport}/equipments`
                  }, null, 8, ["default-href"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Rapport : " + _toDisplayString(_ctx.equipment?.report_sheet?.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['sub-header'])
      }, "Matériel : " + _toDisplayString(_ctx.equipment.reference) + " - " + _toDisplayString(_ctx.equipment.name), 3),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, { side: "left" }, {
            content: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_EquipmentSkeleton)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          class: _normalizeClass(_ctx.$style['custom']),
                          label: "Unité :",
                          placeholder: "Enter data",
                          modelValue: _ctx.equipment.unity,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.equipment.unity) = $event))
                        }, null, 8, ["disabled", "class", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          class: _normalizeClass(_ctx.$style['custom']),
                          label: "Quantité :",
                          placeholder: "Enter data",
                          modelValue: _ctx.equipment.quantity,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.equipment.quantity) = $event))
                        }, null, 8, ["disabled", "class", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, { onClick: _ctx.triggerUserSelector }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          class: _normalizeClass(_ctx.$style['custom']),
                          label: "Employé :",
                          placeholder: "Select data",
                          modelValue: _ctx.employee,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.employee) = $event))
                        }, null, 8, ["disabled", "class", "modelValue"])
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    (!_ctx.equipment.report_sheet?.equipments_step_validated)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_ion_fab, {
                            slot: "fixed",
                            vertical: "bottom",
                            horizontal: "end"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_fab_button, {
                                color: "primary",
                                onClick: _ctx.presentActionSheet
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: _ctx.caretUp }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_UserSelectorButton, {
                            style: {"display":"none"},
                            ref: "userSelectorRef",
                            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectEmployee($event.value)))
                          }, null, 512)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}