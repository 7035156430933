<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-back-button text="Retour" :default-href="`/report-sheets/${$route.params.uuidReport}/employees`"></ion-back-button>
                </ion-buttons>

                <ion-title>Rapport : {{ employee?.report_sheet?.title }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <div :class="$style['sub-header']">Personnel : {{ employee?.firstname }} {{ employee?.lastname }}</div>

        <ion-content>
            <ContentSidebarWrapper side="left">
                <template v-slot:content>
                    <div v-if="loading">
                        <EquipmentSkeleton/>
                    </div>
                    <div v-else>
                        <ion-item>
                            <ion-input :disabled="fieldDisabled" label="Activité" placeholder="Enter data" v-model="employee.activity"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" label="Commentaire" placeholder="Enter data" v-model="employee.comment"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" type="number" label="Déplacement" placeholder="Enter data" v-model="employee.travel"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" type="number" label="Mobilité" placeholder="Enter data" v-model="employee.mobility"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="fieldDisabled" label="Début à" placeholder="00:00" v-model="employee.start_at" @input="calculateTotalHoursWorked"></ion-input>
                            <ion-input :disabled="fieldDisabled" label="Fin à" placeholder="00:00" v-model="employee.end_at" @input="calculateTotalHoursWorked"></ion-input>
                            <ion-input :disabled="fieldDisabled" label="Moins" placeholder="00:00" v-model="employee.rest_time" @input="calculateTotalHoursWorked"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-input :disabled="true" label="Total des heures" placeholder="00:00" v-model="employee.total_hours"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-select :disabled="fieldDisabled" label="Code mobilité" placeholder="Select" v-model="employee.mobility_code">
                                <ion-select-option v-for="mobility in reportSheetMobilities" :key="mobility.uuid" :value="mobility.uuid">
                                    {{ mobility.label }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>

                        <ion-item>
                            <ion-select :disabled="fieldDisabled" label="Prest autres" placeholder="Select" v-model="employee.services_other">
                                <ion-select-option v-for="otherService in reportSheetOtherServices" :key="otherService.uuid" :value="otherService.uuid">
                                    {{ otherService.label }}
                                </ion-select-option>
                            </ion-select>
                        </ion-item>

                        <template v-if="!employee.report_sheet?.employees_step_validated">
                            <ion-fab slot="fixed" vertical="bottom" horizontal="end">
                                <ion-fab-button color="primary" @click="presentActionSheet">
                                    <ion-icon :icon="caretUp"></ion-icon>
                                </ion-fab-button>
                            </ion-fab>
                        </template>
                    </div>
                </template>
            </ContentSidebarWrapper>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import {
        IonBackButton,
        IonButtons,
        IonHeader,
        IonPage,
        IonInput,
        IonIcon,
        IonToolbar,
        actionSheetController,
        alertController,
        IonContent,
        toastController,
        IonSelect,
        IonSelectOption
    } from "@ionic/vue"

    import { defineComponent } from "vue"
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue"
    import { ReportSheet } from "@/report-sheets";
    import EquipmentSkeleton from "@/components/skeletons/EquipmentSkeleton.vue";

    import {
        caretUp
    } from 'ionicons/icons';

    export default defineComponent({
        components: {
            EquipmentSkeleton,
            ContentSidebarWrapper,
            IonBackButton,
            IonButtons,
            IonIcon,
            IonInput,
            IonHeader,
            IonPage,
            IonToolbar,
            IonContent,
            IonSelect,
            IonSelectOption
        },
        setup() {
            return { caretUp }
        },
        data() {
            return {
                fieldDisabled: true,
                loading: false as boolean | false,
                employee: {
                    activity: '',
                    comment: '',
                    travel: '',
                    mobility: '',
                    start_at: '',
                    end_at: '',
                    rest_time: '',
                    total_hours: '',
                    mobility_code: '',
                    services_other: '',
                    firstname: '',
                    lastname: '',
                    report_sheet: null as ReportSheet | null
                },
                reportSheetMobilities: [] as any | [],
                reportSheetOtherServices: [] as any | []
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                if (this.loading)
                    return

                this.loading = true

                Promise.all([
                    this.axios.get(`/report-sheets/${this.$route.params.uuidReport}/employees/${this.$route.params.uuidEmployee}`),
                    this.axios.get(`/report-sheet-mobilities`),
                    this.axios.get(`/report-sheet-other-services`)
                ])
                .then((responses) => {
                    this.employee = responses[0].data
                    this.reportSheetMobilities = responses[1].data
                    this.reportSheetOtherServices = responses[2].data

                    const selectedMobility = this.reportSheetMobilities.find((mobility: any) => this.employee.mobility_code === mobility.label)
                    if (selectedMobility)
                        this.employee.mobility_code = selectedMobility.uuid

                    const selectedOtherService = this.reportSheetOtherServices.find((otherService: any) => this.employee.services_other === otherService.label)
                    if (selectedOtherService)
                        this.employee.services_other = selectedOtherService.uuid

                    if (!this.employee.report_sheet?.employees_step_validated) 
                        this.fieldDisabled = false
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loading = false
                })
            },
            calculateTotalHoursWorked() {
                if (!this.employee.start_at || !this.employee.end_at) {
                    this.employee.total_hours = ""
                    return
                }

                const timeToMinutes = (time: any) => {
                    if (!time || !time.includes(':')) return 0 // Return 0 if the field is empty or incorrectly formatted
                    const [hours, minutes] = time.split(':').map(Number)
                    return (hours * 60) + minutes
                }

                const startMinutes = timeToMinutes(this.employee.start_at)
                const endMinutes = timeToMinutes(this.employee.end_at)
                const restMinutes = timeToMinutes(this.employee.rest_time || "00:00") // Default rest time to 00:00 if empty

                // Ensure values are valid numbers
                if (isNaN(startMinutes) || isNaN(endMinutes) || isNaN(restMinutes)) {
                    this.employee.total_hours = ""
                    return
                }

                const workedMinutes = endMinutes - startMinutes - restMinutes

                if (workedMinutes < 0) {
                    this.employee.total_hours = "00:00" // Prevent negative total
                    return
                }

                const hours = Math.floor(workedMinutes / 60)
                const minutes = workedMinutes % 60

                this.employee.total_hours = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
            },
            async presentActionSheet() {
                const actionSheet = await actionSheetController.create({
                    header: `Personnel : ${this.employee.firstname } - ${this.employee.lastname}`,
                    buttons: [
                        {
                            text: "Enregistrer",
                            handler: () => {
                                this.formSubmit()
                            }
                        },
                        {
                            text: "Supprimer",
                            role: "destructive",
                            handler: async () => {
                                const alert = await alertController.create({
                                    message: 'Etes-vous sûr de vouloir supprimer définitivement ?',
                                    htmlAttributes: {
                                        'aria-label': 'alert dialog'
                                    },
                                    buttons: [
                                        {
                                            text: 'Annuler',
                                            role: "cancel"
                                        },
                                        {
                                            text: 'Confirmer',
                                            role: "confirm",
                                            handler: () => {
                                                this.deleteItem()
                                            }
                                        }
                                    ]
                                })

                                await alert.present()
                            }
                        },
                        {
                            text: "Annuler",
                            role: "cancel"
                        }
                    ]
                })

                await actionSheet.present()
            },
            deleteItem() {
                if (this.loading)
                    return

                this.loading = true
                
                this.axios.delete(`/report-sheets/${this.$route.params.uuidReport}/employees/${this.$route.params.uuidEmployee}`)
                .then(async () => {
                    (
                        await toastController.create({
                            header: 'SUCCESS',
                            message: 'Correctly deleted',
                            duration: 5000,
                            color: "success"
                        })
                    ).present()

                    this.$router.replace(`/report-sheets/${this.$route.params.uuidReport}/employees`)
                }).catch((error) => {
                    console.error(error)
                    this.loading = false
                })
            },
            formSubmit() {
                if (this.loading)
                    return
                
                this.loading = true

                this.axios.put(`/report-sheets/${this.$route.params.uuidReport}/employees/${this.$route.params.uuidEmployee}`, {
                    activity: this.employee.activity,
                    comment: this.employee.comment,
                    travel: this.employee.travel,
                    mobility: this.employee.mobility,
                    start_at: this.employee.start_at,
                    end_at: this.employee.end_at,
                    rest_time: this.employee.rest_time ?? '00:00',
                    total_hours: this.employee.total_hours,
                    mobility_code: this.employee.mobility_code,
                    services_other: this.employee.services_other
                }).then(async () => {
                    (
                        await toastController.create({
                            header: 'SUCCESS',
                            message: 'Correctly updated',
                            duration: 5000,
                            color: "success"
                        })
                    ).present()
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    })
</script>

<style lang="scss" module>
    .sub-header {
        background: #000;
        height: 50px;
        width: 100%;
        display: grid;
        align-content: center;
        justify-content: center;
    }
    ion-input.custom {
        text-align: right;
    }
</style>