import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: "Retour",
                    "default-href": `/report-sheets/${_ctx.sheet?.uuid}`
                  }, null, 8, ["default-href"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Rapport : " + _toDisplayString(_ctx.sheet?.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.loaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_EquipmentSkeleton)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['summary-data-wrapper'])
                }, [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['title'])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Personnel (" + _toDisplayString(_ctx.sheet?.employees.length ?? 0) + ")", 1)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['header'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Matricule")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Personnel")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Activité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Commentaire")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Déplacement")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Mobilité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Début")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Fin")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Moins")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Total")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Code mobilité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Prest. autres")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.employees, (employee) => {
                        return (_openBlock(), _createBlock(_component_ion_row, {
                          class: _normalizeClass(_ctx.$style['data']),
                          key: employee.uuid
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.reference), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.firstname) + " " + _toDisplayString(employee.lastname), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.activity), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.comment), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.travel), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.mobility), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.start_at), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.end_at), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.rest_time), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.total_hours), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.mobility_code), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(employee.services_other), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["class"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['summary-data-wrapper'])
                }, [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['title'])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Matériel (" + _toDisplayString(_ctx.sheet?.equipments.length ?? 0) + ")", 1)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['header'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Référence")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Matériel")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Unité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Quantité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Personnel")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.equipments, (equipment) => {
                        return (_openBlock(), _createBlock(_component_ion_row, {
                          class: _normalizeClass(_ctx.$style['data']),
                          key: equipment.uuid
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(equipment.reference), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(equipment.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(equipment.unity), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(equipment.quantity), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(equipment.employee_firstname) + " " + _toDisplayString(equipment.employee_lastname), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["class"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['summary-data-wrapper'])
                }, [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['title'])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Location (" + _toDisplayString(_ctx.sheet?.locations.length ?? 0) + ")", 1)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['header'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Location IN/OUT")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Unité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Quantité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Personnel")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.locations, (location) => {
                        return (_openBlock(), _createBlock(_component_ion_row, {
                          class: _normalizeClass(_ctx.$style['data']),
                          key: location.uuid
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(location.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(location.unity), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(location.quantity), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(location.employee_firstname) + " " + _toDisplayString(location.employee_lastname), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["class"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style['summary-data-wrapper'])
                }, [
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['title'])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Travail sous-traités (" + _toDisplayString(_ctx.sheet?.outsourced_works.length ?? 0) + ")", 1)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, {
                        class: _normalizeClass(_ctx.$style['header'])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Travail sous-traité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Unité")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Quantité")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_grid, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sheet?.outsourced_works, (outsourced_work) => {
                        return (_openBlock(), _createBlock(_component_ion_row, {
                          class: _normalizeClass(_ctx.$style['data']),
                          key: outsourced_work.uuid
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(outsourced_work.name), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(outsourced_work.unity), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_col, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(outsourced_work.quantity), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["class"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ], 2)
              ]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}