import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import MainScreen from "@/views/MainScreen.vue";

import HomePage from '@/views/tabs/HomePage.vue';
import OrdersPage from '@/views/tabs/OrdersPage.vue';
import NewOrderWizard from '@/views/tabs/orders/NewOrderWizard.vue';
import OrderPage from '@/views/tabs/orders/OrderPage.vue';
import ControlSheetPage from '@/views/tabs/control_sheets/ControlSheetPage.vue';
import EquipmentPage from '@/views/tabs/EquipmentPage.vue';
import EquipmentEntryPage from '@/views/tabs/equipment/EquipmentEntryPage.vue';
import PlanningPage from '@/views/tabs/PlanningPage.vue';
import PlanningEntryPage from '@/views/tabs/planning/PlanningEntryPage.vue';
import PlanningValidationPage from '@/views/tabs/planning/PlanningValidationPage.vue';
import ControlSheetsPage from '@/views/tabs/ControlSheetsPage.vue';
import SiteEntryPage from '@/views/tabs/sites/SiteEntryPage.vue';
import UserPage from '@/views/tabs/UserPage.vue';
import ControlSheet from '@/views/tabs/forms/ControlSheet.vue';

import ReportSheetsPage from '@/views/tabs/ReportSheetsPage.vue';
import ReportSheetStepsPage from '@/views/tabs/report_sheets/ReportSheetStepsPage.vue';
import ReportSheetSummaryPage from '@/views/tabs/report_sheets/ReportSheetSummaryPage.vue';
import EmployeesPage from "@/views/tabs/report_sheets/employees/EmployeesPage.vue";
import EmployeeItem from "@/views/tabs/report_sheets/employees/EmployeeItem.vue";
import EquipmentsPage from "@/views/tabs/report_sheets/equipments/EquipmentsPage.vue";
import EquipmentItem from "@/views/tabs/report_sheets/equipments/EquipmentItem.vue";
import LocationsPage from "@/views/tabs/report_sheets/locations/LocationsPage.vue";
import LocationItem from "@/views/tabs/report_sheets/locations/LocationItem.vue";
import OutsourcedWorksPage from "@/views/tabs/report_sheets/outsourced_works/OutsourcedWorksPage.vue";
import OutsourcedWorktem from "@/views/tabs/report_sheets/outsourced_works/OutsourcedWorktem.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: MainScreen,

        children: [
            {
                path: "",
                redirect: "home",
            },
            {
                path: "home",
                name: "home",
                component: HomePage,
            },
            {
                path: "orders",
                name: "orders.list",
                component: OrdersPage,
            },
            {
                path: "orders/new",
                name: "orders.new",
                component: NewOrderWizard,
            },
            {
                path: "orders/:uuid",
                name: "orders.single",
                component: OrderPage,
            },
            {
                path: "equipment",
                name: "equipment.list",
                component: EquipmentPage,
            },
            {
                path: "equipment/:uuid",
                name: "equipment.single",
                component: EquipmentEntryPage,
            },
            {
                path: "planning",
                name: "planning.list",
                component: PlanningPage,
            },
            {
                path: "planning/:uuid",
                name: "planning.single",
                component: PlanningEntryPage,
            },
            {
                path: "planning/:uuid/validate",
                name: "planning.single.validate",
                component: PlanningValidationPage,
            },
            {
                path: "control-sheets",
                name: "control_sheets.list",
                component: ControlSheetsPage,
            },
            {
                path: "control-sheets/:uuid",
                name: "control_sheets.single",
                component: ControlSheetPage,
            },
            {
                path: "sites/:uuid",
                name: "sites.single",
                component: SiteEntryPage,
            },
            {
                path: "user/:section?",
                name: "user",
                component: UserPage,
            },
            {
                path: "forms/control-sheet",
                name: "forms.control_sheet",
                component: ControlSheet,
                meta: {
                    guest: true
                }
            },
            {
                path: "report-sheets",
                name: "report_sheets.list",
                component: ReportSheetsPage
            },
            {
                path: "report-sheets/:uuidReport",
                name: "report_sheets.single",
                component: ReportSheetStepsPage
            },
            {
                path: "report-sheets/:uuidReport/summary",
                name: "report_sheets.summary",
                component: ReportSheetSummaryPage
            },
            {
                path: "report-sheets/:uuidReport/employees",
                name: "employees.list",
                component: EmployeesPage
            },
            {
                path: "report-sheets/:uuidReport/employees/:uuidEmployee",
                name: "employees.show",
                component: EmployeeItem
            },
            {
                path: "report-sheets/:uuidReport/equipments",
                name: "equipments.list",
                component: EquipmentsPage
            },
            {
                path: "report-sheets/:uuidReport/equipments/:uuidEquipment",
                name: "equipments.show",
                component: EquipmentItem
            },
            {
                path: "report-sheets/:uuidReport/locations",
                name: "locations.list",
                component: LocationsPage
            },
            {
                path: "report-sheets/:uuidReport/locations/:uuidLocation",
                name: "locations.show",
                component: LocationItem
            },
            {
                path: "report-sheets/:uuidReport/outsourced-works",
                name: "outsourced_works.list",
                component: OutsourcedWorksPage
            },
            {
                path: "report-sheets/:uuidReport/outsourced-works/:uuidOutsourcedWork",
                name: "outsourced_work.show",
                component: OutsourcedWorktem
            }
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
