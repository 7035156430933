export default {
    menu_items: {
        home: "Dashboard",
        orders: "Commandes",
        planning: "Planning",
        equipment: "Matériel",
        control_sheets: "Pannes",
        scanner: "Scanner",
        report_sheets: "Rapports journaliers"
    },
    document_titles: {
        home: "Dashboard",
        orders: {
            list: "Bons de commande",
            single: "Bon de commande",
            new: "Ajouter un bon de commande",
        },
        planning: {
            list: "Planning",
            single: "Planning",
        },
        sites: {
            list: "Chantiers",
            single: "Chantier",
        },
        equipment: {
            list: "Suivis matériel",
            single: "Suivi matériel",
        },
        user: "Mon compte",
        forms: {
            control_sheet: 'Fiche "suivi panne"',
        },
        control_sheets: {
            list: "Suivi pannes",
            single: 'Fiche "suivi panne"',
        },
    },
    global: {
        close: "Fermer",
        select: "Sélectionner",
        search: "Rechercher",
        required: "Requis",
        optional: "Optionnel",
        edit: "Modifier",
        cancel: "Annuler",
        actions: "Actions",
        save: "Sauvegarder",
        delete: "Supprimer",
        fields: {
            address: {
                street: "Rue",
                number: "Numéro",
                zip: "Code postal",
                city: "Ville",
                country: "Pays",
            },
        },
    },
    messages: {
        new_placeholder: "Ecrire un message...",
    },
    stats: {
        labels: {
            blocked_planning_entries: "Entrées de planning bloquées",
            orders_to_validate: "Bons de commande en attente de validation",
            planning_entries_to_validate:
                "Entrées de planning en attente de validation",
            control_sheets_to_validate:
                "Fiches de suivi panne en attente de validation",
        },
        actions: {
            more: "C'est parti",
        },
    },
    equipment: {
        screen_title: "Suivi matériel",
        no_entry: "Aucune entrée",
        no_result: "Désolé, aucun matériel n'a été trouvé.",
        no_result_filtered: "Aucun résultat ne correspond à votre recherche.",
        back_button_label: "Retour",
        search_placeholder: "Rechercher",
        types: {
            "small-equipment": "Outillage",
            "rolling-stock": "Mat. roulant",
            supply: "Fournitures",
        },
        filters: {
            status: "Statut",
            status_all: "Tous",
            site: "Chantier",
            user: "Responsable",
        },
        tracker: {
            screen_title: "Tracker",
            next: "Etape suivante",
            equipment_name: "Quel est le matériel utilisé?",
            event: "Quelle action souhaitez-vous encoder?",
            site: "Sur quel chantier le matériel est-il utilisé?",
            user: "Par qui le matériel est-il utilisé?",
            photos: "Photo(s) du matériel",
            comment: "Commentaire",
            comment_placeholder: "Ajoutez ici un commentaire (optionnel)",
            events: {
                "check:in": "Entrée",
                "check:out": "Sortie",
            },
            actions: {
                "check:in": "Enregistrer une entrée de matériel",
                "check:out": "Enregistrer une sortie de matériel",
            },
            success: {
                "check:in": 'L\'entrée de "{equipment}" a été enregistrée avec succès.',
                "check:out":
                    'La sortie de "{equipment}" sur le chantier "{site}" a été enregistrée avec succès.',
            },
            sign: {
                title: "Signature",
                error: "La signature est obligatoire",
                clear: "Effacer",
                success: "Le document a été signé avec succès",
            },
        },
        status: {
            check_in: "Au dépot",
            check_out: "Sur chantier",
            'check:in': "Au dépot",
            'check:out': "Sur chantier",
        },
    },
    files: {
        types: {
            document: "Document",
            order: "Bon de commande",
            provider_pdf: "Fichier fournisseur",
            shipping: "Bon de livraison",
            qualiroutes: "Qualiroutes",
            cmr: "CMR",
            walterre: "Walterre",
            work: "Bon de régie",
            "control-sheet": 'Fiche "suivi panne"',
            "equipment-state": "Etat du matériel",
        },
    },
    calendar: {
        today: "Aujourd'hui",
        tomorrow: "Demain",
        yesterday: "Hier",
        minutes: "minutes",
    },
    widgets: {
        folder_selector: {
            title: "Sélectionner un dossier",
        },
        site_selector: {
            title: "Sélectionner un chantier",
            no_result: "Aucun chantier trouvé",
        },
        equipment_selector: {
            title: "Sélectionner un matériel",
            no_result: "Aucun matériel trouvé",
        },
        provider_selector: {
            title: "Sélectionnez un fournisseur",
            new_provider: "Ajouter",
            new_provider_description: "Créer un nouveau fournisseur.",
            edit: {
                title: "Informations du fournisseur",
            },
            no_result: "Désolé, aucun fournisseur n'a été trouvé.",
        },
        user_selector: {
            title: "Sélectionnez un utilisateur",
            no_result: "Aucun utilisateur n'a été trouvé.",
        },
    },
    forms: {
        provider: {
            name: "Nom",
            email: "Email",
            iban_number: "IBAN",
            vat_number: "Numéro de TVA",
            billing_address: "Adresse de facturation",
        },

        control_sheet: {
            title: 'Fiche "suivi panne"',
            select_site: "Sélectionner un chantier",
            submit: "Envoyer la fiche",
            success: {
                title: "Merci!",
                description: "Votre fiche a été envoyée avec succès.",
            },
        },
    },
    home: {
        screen_title: "Dashboard",
        welcome: "Bienvenue",
    },
    users: {
        roles: {
            issuer: "Émetteur",
            emitter: "Émetteur",
            submitter: "Émetteur",
            validator: "Validateur",
            "site-supervisor": "Conducteur de chantier",
            assignee: "Exécutant",
            user: "Utilisateur",
            contractor: "Entrepreneur",
            viewer: "Visibilité"
        },
    },
    user: {
        screen_title: "Mon compte",
        informations: {
            title: "Informations",
            first_name: "Prénom",
            last_name: "Nom",
            email: "Email",
        },
        credentials: {
            title: "Connexion",
            current_password: "Mot de passe actuel",
            new_password: "Nouveau mot de passe",
            new_password_verif: "Confirmation du mot de passe",
            change_password_submit: "Changer le mot de passe",
            success_title: "Mot de passe changé",
            success_message: "Votre mot de passe a été changé avec succès.",
        },
        logout: "Se déconnecter",
    },
    sites: {
        screen_title: "Chantiers",
        back_button_label: "Retour",
    },
    orders: {
        screen_title: "Bons de commande",
        back_button_label: "Commandes",
        new_order_label: "Ajouter",
        new_button_label: "Ajouter un bon de commande",
        search_placeholder: "Rechercher",
        no_result: "Désolé, aucun bon de commande n'a été trouvé.",
        no_result_filtered:
            "Désolé, aucun bon de commande ne correspond à votre recherche.",
        actions: {
            delete: "Supprimer",
            delete_confirm: "Êtes-vous sûr de vouloir supprimer ce bon de commande ?",
            delete_success: "Le bon de commande a été supprimé avec succès.",
            approve: "Approuver",
            refuse: "Refuser",
            refuse_confirm: "Êtes-vous sûr de vouloir refuser ce bon de commande ?",
            refuse_reason: "Raison du refus",
            save: "Sauvegarder",
            save_confirm:
                "Une fois sauvegardé, vous ne pourrez plus modifier le bon de commande. Voulez-vous continuer?",
            save_success: "Le bon de commande a été sauvegardé avec succès.",
        },
        status: {
            unknown: "",
            validation: "En attente d'approbation",
            pending_validation: "En attente d'approbation",
            running_validation: "En cours d'approbation",
            done: "Validé",
            draft: "Brouillon",
            refused: "Refusé",
            pending_sync: "Synchronisation en cours",
        },
        tabs: {
            informations: "Informations",
            validation: "Validation",
            comments: "Commentaires",
            provider: "Fournisseur",
            roles: "Responsables",
        },
        fields: {
            reference: "Réference",
            status: "Statut",
            folder: "Dossier",
            created_at: "Date",
            updated_at: "Dernière modification",
            company: "Société",
            site: "Chantier",
            validation: {
                you: "Vous",
            },
            provider: {
                name: "Fournisseur",
                email: "Email",
                iban_number: "IBAN",
                vat_number: "Numéro de TVA",
                billing_address: "Adresse de facturation",
            },
        },
        validation: {
            validate_button_label: "Approuver le bon de commande",
            validated_button_label: "Vous avez validé le bon de commande",
            comment_placeholder: "Ajouter un commentaire",
        },
        products_table: {
            labels: {
                sku: "Réf.",
                label: "Label",
                quantity: "Qté.",
                unit: "Unit.",
                price: "Prix unit.",
                total: "Total",
                subtotal: "Sous-total",
                vat: "TVA",
            },
        },
        comment_field: {
            placeholder: "Ajouter un commentaire",
        },
        delivery_address_field: {
            placeholder: "Ajouter une adresse de livraison",
        },
        defined_comments: {
            label: "Commentaires pré-définis",
            values: JSON.stringify([
                "A livrer au plus vite",
                "Bon de commande validé!",
            ]),
        },
        toasts: {
            approve_no_provider_email:
                "Le fournisseur de cette commande n'a pas d'adresse email. Veuillez en ajouter une pour pouvoir approuver la commande.",
            approved_success: "Le bon de commande a été approuvé avec succès",
            refused_success: "Le bon de commande a été refusé avec succès",
        },
        new: {
            screen_title: "Ajouter un bon de commande",
            folder_label: "Dossier",
            site_label: "Chantier",
            informations_label: "Informations",
            provider_label: "Fournisseur",
            next_button_label: "Etape suivante",
            creating_order_label: "Création du bon de commande",
            new_line: "Ajouter une nouvelle ligne",
        },
        folder_labels: {
            management: "Direction",
            supply: "Fournitures",

            site: "Chantier",
            subcontractor: "Sous-traitant",
            rental: "Location",

            panneau_chantier: "Panneau de chantier",
            install_chantier: "Installation de chantier",
            egouttage_beton: "Egouttage béton",
            egouttage_hors_beton: "Egouttage hors béton",
            geotextile: "Geotextile",
            echelle: "Echelle",
            trapillon_avaloir: "Trapillon - Avaloir",
            element_lineaire: "Élément linéaire",
            pave: "Pavé",
            paveur: "Paveur",
            mobilier_urbain: "Mobilier urbain",
            produit: "Produit",
            mur_soutenement: "Mur de soutènement",
            caniveau: "Caniveau",
            eau: "Eau",

            etat_lieux: "État des lieux",
            plan_asbuilt: "Plan as-built",
            essai: "Essai",
            marquage: "Marquage",
            signalisation_verticale: "Signalisation verticale",
            plantation: "Plantation",
            regie: "Régie",
            cloture: "Clôture",
            rabattement: "Rabattement",
            electricite: "Électricité",
            electromecanique: "Électromécanique",
            ferronerie: "Ferronerie",
            forage_dirige: "Forage dirigé",
            foncage: "Fonçage",
        },
        filters: {
            status: "Statut",
            status_all: "Tous",
            folder: "Dossier",
            site: "Chantier",
            roles: "Responsables",
        },
    },
    planning: {
        screen_title: "Planning",
        back_button_label: "Planning",
        no_entry: "Désolé, aucun élément planifié n'a été trouvé.",
        status: {
            planned: "Planifié",
            running: "En cours",
            to_review: "A contrôler",
            validated: "Validé",
            changed: "En cours de validation",
            blocked: "Bloqué",
        },
        timeline: {
            title: "Horaire planifié",
        },
        review: {
            title: "Le travail a-t-il bien été effectué dans les heures prévues?",
            yes: "Oui",
            no: "Non, je souhaite ajuster les heures",
            validate: "Valider les heures",
            validator_edit_hours: "Ajuster les heures de travail",
            edit_hours: {
                title: "Ajuster les heures de travail",
                subtitle: "Quel est l'horaire effectué par ",
                starts_at: "Début",
                ends_at: "Fin",
                pause_duration: "Pause",
                duration: "Durée du travail",
                validate: "Valider le travail",
                changed_reason: "Raison du changement",
                changed_reason_button: "Commenter",
            },
            files: {
                //title: "Ajouter des fichiers",
                subtitle:
                    "Pour valider ce travail, merci de bien vouloir joindre les fichiers suivants.",
                shipping_file: "Bon de livraison",
                qualiroutes_file: "Bon Qualiroutes",
                cmr_file: "Bon CMR",
                walterre_file: "Bon Walterre",
                work_file: "Bon de régie",
                validate: "Etape suivante",
            },
            blocked:
                "Cette tâche doit être validée manuellement afin de pouvoir procéder à la facturation. Veuillez nous contacter.",
            disclaimer:
                "Chaque tâche du planning doit être validée via cette plateforme afin de pouvoir être facturée.",
            contact_button: "Nous contacter",
            manual_validation: {
                title: "Validation manuelle",
            },
        },
    },
    control_sheets: {
        screen_title: "Suivi pannes",
        no_result: "Désolé, aucune fiche n'a été trouvée.",
        new: {
            screen_title: "Nouvelle fiche",
        },
        levels: {
            low: "Faible",
            normal: "Normal",
            urgent: "Urgent",
            already_repaired: "Déjà réparé",
        },
        status: {
            pending_validation: "En attente de validation",
        },
        back_button_label: "Suivi panne",
        tabs: {
            informations: "Informations",
            user: "Utilisateur",
            roles: "Responsables",
            validation: "Validation",
        },
        fields: {
            reference: "Référence",
            status: "Statut",
            date: "Date",
            first_name: "Prénom",
            last_name: "Nom",
            email: "Email",
            filled_by: "Soumis par",
            cause: "Cause",
            validation: {
                you: "Vous",
            },
            level: "Niveau d'urgence",
        },
    },
    report_sheets: {
        screen_title: "Rapports journaliers",
        no_result: "Désolé, aucun raport n'a été trouvé.",
        back_button_label: "Rapports journaliers",
    },
    auth: {
        login_form: {
            email: {
                label: "Adresse e-mail",
                placeholder: "",
            },
            password: {
                label: "Mot de passe",
                placeholder: "••••••••",
            },
            action_label: "Se connecter",
            reset_label: "Mot de passe oublié ?",
        },
        reset_form: {
            action_label: "Réinitialiser",
            success_title: "Vérifiez vos e-mails!",
            success_message:
                "Si un compte correspondant a été trouvé, vous devriez avoir reçu un lien pour réunitialiser votre mot de passe.",
        },
    },
    toast: {
        error: {
            title: "Oooops!",
            not_found: "Désolé, nous n'avons pas pu trouver ce que vous cherchez.",
        },
    },
};