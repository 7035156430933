import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentSkeleton = _resolveComponent("EquipmentSkeleton")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ContentSidebarWrapper = _resolveComponent("ContentSidebarWrapper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    text: "Retour",
                    "default-href": `/report-sheets/${_ctx.$route.params.uuidReport}/employees`
                  }, null, 8, ["default-href"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Rapport : " + _toDisplayString(_ctx.employee?.report_sheet?.title), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style['sub-header'])
      }, "Personnel : " + _toDisplayString(_ctx.employee?.firstname) + " " + _toDisplayString(_ctx.employee?.lastname), 3),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ContentSidebarWrapper, { side: "left" }, {
            content: _withCtx(() => [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_EquipmentSkeleton)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          label: "Activité",
                          placeholder: "Enter data",
                          modelValue: _ctx.employee.activity,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.employee.activity) = $event))
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          label: "Commentaire",
                          placeholder: "Enter data",
                          modelValue: _ctx.employee.comment,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.employee.comment) = $event))
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          type: "number",
                          label: "Déplacement",
                          placeholder: "Enter data",
                          modelValue: _ctx.employee.travel,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.employee.travel) = $event))
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          type: "number",
                          label: "Mobilité",
                          placeholder: "Enter data",
                          modelValue: _ctx.employee.mobility,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.employee.mobility) = $event))
                        }, null, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          label: "Début à",
                          placeholder: "00:00",
                          modelValue: _ctx.employee.start_at,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.employee.start_at) = $event)),
                          onInput: _ctx.calculateTotalHoursWorked
                        }, null, 8, ["disabled", "modelValue", "onInput"]),
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          label: "Fin à",
                          placeholder: "00:00",
                          modelValue: _ctx.employee.end_at,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.employee.end_at) = $event)),
                          onInput: _ctx.calculateTotalHoursWorked
                        }, null, 8, ["disabled", "modelValue", "onInput"]),
                        _createVNode(_component_ion_input, {
                          disabled: _ctx.fieldDisabled,
                          label: "Moins",
                          placeholder: "00:00",
                          modelValue: _ctx.employee.rest_time,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.employee.rest_time) = $event)),
                          onInput: _ctx.calculateTotalHoursWorked
                        }, null, 8, ["disabled", "modelValue", "onInput"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          disabled: true,
                          label: "Total des heures",
                          placeholder: "00:00",
                          modelValue: _ctx.employee.total_hours,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.employee.total_hours) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          disabled: _ctx.fieldDisabled,
                          label: "Code mobilité",
                          placeholder: "Select",
                          modelValue: _ctx.employee.mobility_code,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.employee.mobility_code) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportSheetMobilities, (mobility) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: mobility.uuid,
                                value: mobility.uuid
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(mobility.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_select, {
                          disabled: _ctx.fieldDisabled,
                          label: "Prest autres",
                          placeholder: "Select",
                          modelValue: _ctx.employee.services_other,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.employee.services_other) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportSheetOtherServices, (otherService) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: otherService.uuid,
                                value: otherService.uuid
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(otherService.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["disabled", "modelValue"])
                      ]),
                      _: 1
                    }),
                    (!_ctx.employee.report_sheet?.employees_step_validated)
                      ? (_openBlock(), _createBlock(_component_ion_fab, {
                          key: 0,
                          slot: "fixed",
                          vertical: "bottom",
                          horizontal: "end"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_fab_button, {
                              color: "primary",
                              onClick: _ctx.presentActionSheet
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.caretUp }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}