import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ScannerPanel = _resolveComponent("ScannerPanel")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_EquipmentTrackerPanel = _resolveComponent("EquipmentTrackerPanel")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_NotificationsBlock = _resolveComponent("NotificationsBlock")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, {
        class: _normalizeClass(_ctx.$style['split-pane']),
        "content-id": "main-content",
        when: "lg"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            class: _normalizeClass(_ctx.$style['ion-menu']),
            "content-id": "main-content",
            type: "push"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, { color: "light" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['menu'])
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass({
                [_ctx.$style['menu-logo']]: true,
                'ion-padding': true,
              }),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo($event, ''))),
                      innerHTML: _ctx.user?.current_organization?.logo_svg
                    }, null, 10, _hoisted_1),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style['nav-links']),
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeMenu && _ctx.closeMenu(...args)))
                    }, [
                      _createVNode(_component_ion_button, {
                        color: _ctx.isActive('home') ? 'primary' : 'dark',
                        buttonType: "button",
                        expand: "full",
                        fill: "clear",
                        "router-link": "/home",
                        routerDirection: "root"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['nav-link'])
                          }, [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.dashboardSpeed
                            }, null, 8, ["icon"]),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu_items.home")), 1)
                          ], 2)
                        ]),
                        _: 1
                      }, 8, ["color"]),
                      (_ctx.user.rights?.includes('orders.view'))
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            color: _ctx.isActive('orders') ? 'primary' : 'dark',
                            buttonType: "button",
                            expand: "full",
                            fill: "clear",
                            "router-link": "/orders",
                            routerDirection: "root"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['nav-link'])
                              }, [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  icon: _ctx.googleDocs
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu_items.orders")), 1)
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      (_ctx.user.rights?.includes('planning_entries.view'))
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            color: _ctx.isActive('planning') ? 'primary' : 'dark',
                            buttonType: "button",
                            expand: "full",
                            fill: "clear",
                            "router-link": "/planning",
                            routerDirection: "root"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['nav-link'])
                              }, [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  icon: _ctx.alarm
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu_items.planning")), 1)
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      (
                  _ctx.user.rights?.includes('usage_trackings.view') ||
                  _ctx.user.rights?.includes('equipments.view')
                )
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 2,
                            color: _ctx.isActive('equipment') ? 'primary' : 'dark',
                            buttonType: "button",
                            expand: "full",
                            fill: "clear",
                            "router-link": "/equipment",
                            routerDirection: "root"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['nav-link'])
                              }, [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  icon: _ctx.tools
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu_items.equipment")), 1)
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      (_ctx.user.rights?.includes('control_sheets.view'))
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 3,
                            color: _ctx.isActive('control-sheets') ? 'primary' : 'dark',
                            buttonType: "button",
                            expand: "full",
                            fill: "clear",
                            "router-link": "/control-sheets",
                            routerDirection: "root"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['nav-link'])
                              }, [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  icon: _ctx.wench
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu_items.control_sheets")), 1)
                              ], 2)
                            ]),
                            _: 1
                          }, 8, ["color"]))
                        : _createCommentVNode("", true),
                      _withDirectives(_createVNode(_component_ion_button, {
                        color: _ctx.isActive('report-sheets') ? 'primary' : 'dark',
                        buttonType: "button",
                        expand: "full",
                        fill: "clear",
                        "router-link": "/report-sheets",
                        routerDirection: "root"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['nav-link'])
                          }, [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              icon: _ctx.wench
                            }, null, 8, ["icon"]),
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu_items.report_sheets")), 1)
                          ], 2)
                        ]),
                        _: 1
                      }, 8, ["color"]), [
                        [_vShow, false]
                      ]),
                      (_ctx.is_mobile)
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 4,
                            buttonType: "button",
                            color: "dark",
                            expand: "full",
                            fill: "clear",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => {
                  $event.preventDefault();
                  $event.stopPropagation();
                  _ctx.scanner_modal = true;
                })
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['nav-link'])
                              }, [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  icon: _ctx.scanQrCode
                                }, null, 8, ["icon"]),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t("menu_items.scanner")), 1)
                              ], 2)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ], 2),
                    (_ctx.user.is_logged_in)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          class: _normalizeClass({
                [_ctx.$style['user-item']]: true,
              }),
                          color: "medium",
                          expand: "full",
                          fill: "clear",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.user_modal = true))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass(_ctx.$style['user-item-inner'])
                            }, [
                              _createVNode(_component_ion_avatar, {
                                class: _normalizeClass({
                    [_ctx.$style['menu-avatar']]: true,
                  })
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.user?.profile_picture)
                                    ? (_openBlock(), _createBlock(_component_ion_img, {
                                        key: 0,
                                        src: _ctx.user.profile_picture
                                      }, null, 8, ["src"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["class"]),
                              _createElementVNode("p", {
                                class: _normalizeClass({
                    [_ctx.$style['menu-username']]: true,
                  })
                              }, _toDisplayString(_ctx.user.display_name), 3)
                            ], 2)
                          ]),
                          _: 1
                        }, 8, ["class"]))
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_modal, {
                backdropDismiss: true,
                canDismiss: true,
                "is-open": _ctx.scanner_modal,
                onWillDismiss: _cache[6] || (_cache[6] = ($event: any) => (_ctx.scanner_modal = false))
              }, {
                default: _withCtx(() => [
                  (_ctx.scanner_modal)
                    ? (_openBlock(), _createBlock(_component_ScannerPanel, {
                        key: 0,
                        modalControls: true,
                        onAction: _ctx.scannerAction,
                        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.scanner_modal = false)),
                        onSkip: _cache[5] || (_cache[5] = ($event: any) => {
              _ctx.scanner_modal = false;
              _ctx.equipment_tracker_modal = true;
            })
                      }, null, 8, ["onAction"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["is-open"]),
              _createVNode(_component_ion_modal, {
                backdropDismiss: true,
                canDismiss: true,
                "is-open": _ctx.equipment_tracker_modal,
                onWillDismiss: _cache[9] || (_cache[9] = ($event: any) => (_ctx.equipment_tracker_modal = false))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_header, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_toolbar, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_buttons, { slot: "end" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.equipment_tracker_modal = false))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("global.close")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.equipment_tracker_modal)
                    ? (_openBlock(), _createBlock(_component_EquipmentTrackerPanel, {
                        key: 0,
                        equipment: _ctx.equipment_tracker_item,
                        onClose: _cache[8] || (_cache[8] = ($event: any) => (_ctx.equipment_tracker_modal = false))
                      }, null, 8, ["equipment"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["is-open"]),
              _createVNode(_component_ion_modal, {
                backdropDismiss: true,
                breakpoints: [0, 1],
                canDismiss: true,
                class: _normalizeClass(_ctx.$style['user-modal']),
                "initial-breakpoint": 1,
                "is-open": _ctx.user_modal,
                onWillDismiss: _cache[10] || (_cache[10] = ($event: any) => (_ctx.user_modal = false))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass([_ctx.$style['user-menu'], "ion-padding"])
                  }, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.user?.display_name), 1)
                  ], 2),
                  _createVNode(_component_ion_list, {
                    class: _normalizeClass(_ctx.$style['organizations-list'])
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.user?.organizations, (organization) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: organization.id,
                          class: _normalizeClass({
                [_ctx.$style['is-selected']]:
                  organization.id === _ctx.user.current_organization.id,
              }),
                          button: "button",
                          onClick: ($event: any) => (_ctx.switchToOrganization(organization.id))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_avatar, { slot: "start" }, {
                              default: _withCtx(() => [
                                (organization?.profile_picture)
                                  ? (_openBlock(), _createBlock(_component_ion_img, {
                                      key: 0,
                                      src: organization.profile_picture
                                    }, null, 8, ["src"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(organization.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["class"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_ion_button, {
                      color: "danger",
                      expand: "full",
                      fill: "clear",
                      onClick: _ctx.logoutUser
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("user.logout")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["class", "is-open"])
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_ion_router_outlet, {
            id: "main-content",
            animated: _ctx.is_mobile
          }, null, 8, ["animated"])
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_ion_loading, { isOpen: _ctx.changing_organization }, null, 8, ["isOpen"]),
      _createVNode(_component_NotificationsBlock)
    ]),
    _: 1
  }))
}