<template>
    <ion-page>
        <ion-header>
            <ion-toolbar color="primary">
                <ion-buttons slot="start">
                    <ion-menu-button></ion-menu-button>
                </ion-buttons>

                <ion-title>{{ $t("report_sheets.screen_title") }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <ContentSidebarWrapper side="left">
                <template v-slot:content>
                    <div v-if="loading">
                        <ReportSheetsSkeleton />
                    </div>
                    <div v-else-if="sheets">
                        <ion-item v-for="sheet in sheets" :key="sheet.uuid" :sheet="sheet" :button="true" @click="$router.push('/report-sheets/' + sheet.uuid)">
                            <div :class="$style['inner']">
                                <div>
                                    <h4>{{ sheet.title }}</h4>
                                    <p>Total : {{ sheet.total_cost }}€</p>
                                </div>

                                <div :class="$style['right']">
                                    <p v-if="sheet.status == 'pending'">En cours</p>

                                    <template v-if="sheet.status == 'validated'">
                                        <p :class="$style['raport-validated']">Validé</p>
                                        <p>{{ moment(sheet.validated_at).format("DD/MM/YYYY") }}</p>
                                    </template>
                                </div>
                            </div>
                        </ion-item>
                    </div>
                    <div v-else>
                        <ion-text class="ion-text-center" color="medium">
                            <p>{{ $t("report_sheets.no_result") }}</p>
                        </ion-text>
                    </div>
                </template>
            </ContentSidebarWrapper>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
    import ContentSidebarWrapper from "@/components/wrappers/ContentSidebarWrapper.vue";
    import ReportSheetsSkeleton from "@/components/skeletons/ReportSheetsSkeleton.vue";
    import { defineComponent } from "vue"
    import {
        IonButtons,
        IonContent,
        IonHeader,
        IonMenuButton,
        IonPage,
        IonText,
        IonTitle,
        IonToolbar
    } from "@ionic/vue"
    
    import { ReportSheet } from "@/report-sheets";
    import moment from 'moment';

    export default defineComponent({
        components: {
            IonTitle, IonToolbar, IonButtons, ReportSheetsSkeleton,
            IonContent, IonText,
            ContentSidebarWrapper, IonPage, IonMenuButton, IonHeader
        },
        setup() {
            return {
                moment
            }
        },
        data() {
            return {
                page: 1,
                loading: false,
                sheets: null as ReportSheet | null
            }
        },
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.loading = true

                this.axios.get('/report-sheets?page=' + this.page)
                .then((response) => {
                    this.sheets = response.data
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    this.loading = false
                })
            }
        }
    })
</script>

<style lang="scss" module>
    .inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1em;
        font-size: .9em;

        @media(max-width: 990px) {
            font-size: .8em;
        }
        @media(max-width: 700px) {
            flex-direction: column
        }
        h4, p {
            padding: 0;
            margin: 0;
        }
        p {
            margin-top: .25em;
            color: var(--ion-color-medium);
        }
        .right {
            display: flex;
            align-items: center;

            @media(min-width: 701px) {
                flex-direction: column;
                align-items: flex-end;
            }

            @media(max-width: 700px) {
                .date {
                    &:before {
                        content: '—';
                        margin: 0 .5em;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    .raport-validated {
        color: var(--ion-color-success) !important;
        font-weight: bold;
    }
</style>